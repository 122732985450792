import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import { MEDIA_MOBILE } from "../const"
import Layout from "../components/layout"
import Heading2 from "../components/heading2"
import Metadata from "../components/metadata"

import { BlogListQuery } from "../../types/graphql-types"
import { BlogListPageContext } from "../gatsby-node"

interface Props {
  data: BlogListQuery
  pageContext: BlogListPageContext
  location: Location
}

const Post: React.FC<Props> = ({ data, pageContext, location }) => {
  return (
    <Layout>
      <Metadata
        title="Blog"
        pagePath={location.pathname}
        description="ぁゃぴのブログ記事一覧"
      />
      <Wrapper>
        <Heading2 feedUrl="/rss-blog.xml">Blog</Heading2>

        {data.entries.edges.map(({ node }) => (
          <BlogItem to={`/blog/post/${node.slug}/`} key={node.id}>
            <div>
              <DateTime>
                <time dateTime={node.publishDate}>{node.publishDate}</time>
              </DateTime>
              <Title>{node.title}</Title>
              <Excerpt>{node.content.childMarkdownRemark.excerpt}</Excerpt>
            </div>
          </BlogItem>
        ))}
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query BlogList($skip: Int!, $limit: Int!) {
    entries: allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          slug
          content {
            childMarkdownRemark {
              html
              excerpt
            }
          }
          publishDate
        }
      }
    }
  }
`

export default Post

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 40em;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
    padding: 0 20px;
    padding-top: 0;
  }
`
const BlogItem = styled(Link)`
  display: block;
  position: relative;

  > div {
    padding: 32px 50px 32px 0;
    border-bottom: 1px solid #666;
  }

  &:first-of-type > div {
    border-top: 1px solid #666;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-width: 1px 1px 0 0;
    border-color: #666;
    border-style: solid;
    right: 10px;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
    transition: right 0.2s linear;
  }

  &:hover::after {
    right: 5px;
  }
`
const DateTime = styled.aside`
  font-size: smaller;
  margin-bottom: 20px;
`
const Title = styled.h3`
  font-size: 1.6em;
  line-height: 1.4;
  margin-bottom: 20px;
`
const Excerpt = styled.p`
  line-height: 1.6;
`
